import io from 'socket.io-client';
import store from 'Redux/store';
import { fetchData, recieveNewChat, ACTIONS } from 'Redux/actions';


let socket;
function connect() {
    if (socket) return;
    socket = io.connect(window.location.origin);
    console.log('Connected.', window.location.origin);
    socket.on('connect', () => {
        socket.on('join-chat', (msg) => {
            store.dispatch(recieveNewChat(msg));
        });
        socket.on('new-message', (msg) => {
            const { user } = store.getState();
            console.debug(msg);
            if (msg.userId == user.userId) {
                return;
            }
            store.dispatch(recieveNewChat(msg));
        });
        socket.on('data-update', (data) => {
            const { user } = store.getState();
            if (data.initiator == user.userId) {
                return;
            }
            let type;
            switch (data.type) {
                case 'uni': type = ACTIONS.FETCH_UNI; break;
                case 'chats': type = ACTIONS.FETCH_CHATS; break;
                case 'tasks': type = ACTIONS.FETCH_TASKS; break;
                case 'users': type = ACTIONS.FETCH_USERS; break;
                case 'admins': type = ACTIONS.FETCH_ADMINS; break;
                case 'classes': type = ACTIONS.FETCH_CLASSES; break;
                case 'lecturers': type = ACTIONS.FETCH_LECTURERS; break;
                default: break;
            }
            if ((data.type == 'admins' || data.type == 'lecturers') &&
                user.role !== 'administrator') return;
            store.dispatch(fetchData(type));
        });
    });
}

/**
 * Join a chat group
 * @param {{groupId:string}} data 
 */
function joinGroup(data) {
    socket.emit('request-join', data);
}

export {
    connect, joinGroup
}