// import UnauthenticatedPages from 'Components/app/UnauthenticatedPages';
import NotificationManager from 'Components/NotificationManager';
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { login } from 'Redux/actions';
import styled from 'styled-components';
import Loader from 'Components/Loader';

const AuthPagesPromise = import('Components/app/AuthenticatedPages');
const AuthenticatedPages = React.lazy(() => AuthPagesPromise);
const UnauthPagesPromise = import('Components/app/UnauthenticatedPages');
const UnauthenticatedPages = React.lazy(() => UnauthPagesPromise);

function App() {
    const dispatch = useDispatch();
    const loading = useSelector(state => Boolean(state.pending.length));
    const [loaderVisible, setLoaderVisible] = useState(true);

    useEffect(() => {
        dispatch(login());
    }, []);

    useEffect(() => {
        timeout(1000);
        function timeout(s = 3000){
            window.setTimeout(() => {
                if(loading){
                    timeout(1000);
                } else {
                    setLoaderVisible(false);
                }
            }, s);
        }
    }, []);

    if(loaderVisible){
        return <Loader />
    }

    return (
        <Wrapper>
            <Suspense className='sus' fallback={Loader()} >
                <Switch>
                    <Route path='/user' component={AuthenticatedPages} />
                    <Route path='/' component={UnauthenticatedPages} />
                </Switch>
            </Suspense>
            <NotificationManager />
        </Wrapper>
    );
}


const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.palette.background.default};
    min-height: 100vh;
    .MuiTooltip-tooltip {
        font-size: ${({ theme }) => theme.typography.body2.fontSize};
    }
    .uiDataTable {
        td, th  {
            font-size: ${({ theme }) => theme.typography.body2.fontSize};
        }
    }
    #spacer {
        ${({ theme }) => theme.mixins.toolbar}
    }
`;

export default App;
